// Font Weights
$fw-thin: 100;
$fw-x-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-s-bold: 600;
$fw-bold: 700;
$fw-x-bold: 800;
$fw-black: 900;
