@import "vendor/normalize";
@import "vendor/reset";
@import "vars";

@import "~@fontsource/oswald/index.css";
@import "~@fontsource/oswald/200.css";
@import "~@fontsource/oswald/300.css";
@import "~@fontsource/oswald/400.css";
@import "~@fontsource/oswald/500.css";
@import "~@fontsource/oswald/600.css";
@import "~@fontsource/oswald/700.css";
@import "~@fontsource/open-sans/400.css";

body {
  font-family: 'Oswald', sans-serif;
  font-weight: $fw-regular;
  background-color: #19181D;
  color: #FFF;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  a {
    color: #FFF;
    text-decoration: none;
  }
}

.container {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
}

.button {
  display: inline-block;
  padding: 17px 27px;
  font-size: 20px;
  text-transform: uppercase;
  background-color: #5D02E6;
  transition: opacity 200ms ease;
  backdrop-filter: blur(20px);
  font-weight: $fw-medium;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }

  &.alt {
    background-color: rgba(255, 255, 255, .1);
  }
}

.section-heading {
  h2 {
    font-weight: $fw-medium;
    font-size: 60px;
    color: #FFFFFF;
    text-align: center;
    line-height: 89px;
    text-transform: uppercase;

    span {
      color: #5D02E6;
    }
  }
  p {
    opacity: 0.6;
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
    line-height: 45px;
  }
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-image: url("../../assets/images/particles.png");
  animation-name: particles;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  &.secondary {
    animation-delay: 100ms;
  }
}

nav {
  padding: 20px 0;
  background: rgba(0,0,0,0.80);
  backdrop-filter: blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ul {
    //z-index: 10000;
    li {
      display: inline-block;
      margin: 0 25px;
      position: relative;
      z-index: 10000;
    }
    a {
      font-size: 20px;
      text-decoration: none;
      color: #fff;
      transition: opacity 200ms ease;
      font-weight: $fw-medium;
      text-transform: uppercase;
      opacity: .5;
      &:hover {
        opacity: 1;
        animation-name: flicker;
        animation-iteration-count: infinite;
        animation-duration: 100ms;
        animation-delay: 100ms;
      }
    }
  }
  .logo {
    animation-name: logoTilt;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    animation-timing-function: ease;
  }

  .button.mobile {
    display: none;
  }
}

header.header {
  padding: 300px 0;
  position: relative;
  overflow: hidden;
  background-image: url("../../assets/images/banner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .cta {
    position: relative;
    z-index: 50;
    height: 458px;
    width: 100%;
    max-width: 820px;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    background-image: url("../../assets/images/header.png");
    background-size: contain;
    background-repeat: no-repeat;
    .button-group {
      margin: 0 auto;
      .button {
        margin: 0 10px;
      }
    }
  }



  .text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-image: url("../../assets/images/text.png");
    animation-name: text;
    animation-duration: 45s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.feature {
  display: flex;
  flex-direction: row;
  .icon {
    width: 60px;
    margin-top: 15px;
    svg {}
  }
  .text {
    flex: 1;
    h3 {
      font-size: 34px;
      line-height: 51px;
      color: rgba(255, 255, 255, 0.6);
      span {
        color: rgba(255, 255, 255, 1);
      }
    }
    p {
      color: rgba(255, 255, 255, 0.6);
      font-size: 24px;
      line-height: 36px;
      //font-family: "Open Sans", sans-serif;
      //font-weight: bold;
    }
  }
}

.question {
  backdrop-filter: blur(20px);
  background-color: rgba(49, 48, 58, 0.8);
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  transition: background-color 300ms ease, box-shadow 300ms ease;
  header {
    padding-right: 50px;
    position: relative;
    h3 {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.8);
      transition: color 200ms ease;
      line-height: 26px;
    }
    svg {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 300ms ease;
    }
  }
  main {
    padding-top: 10px;
    p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0);
      transition: color 200ms ease;
      //font-family: 'Open Sans', sans-serif;
      line-height: 18px;
    }
  }
  &.open {
    background-color:  rgba(93,2,230,0.50);
    box-shadow: 0 15px 30px 5px rgba(0,0,0,0.50);
    header svg {
      transform: rotate(45deg) translateY(-50%);
    }
    header h3 {
      color: #fff;
    }
    main p {
      color: #fff;
    }
  }
}

.features-section {
  padding: 100px 0;
  background-image: url("../../assets/images/trees.jpg");
  background-size: cover;
  background-position: center;
  .features-grid {
    //margin-top: 36px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 96px;
    grid-row-gap: 30px;
  }
}

.partners-section {
  height: 500px;
  position: relative;
  background-image: radial-gradient(50% 100%, #25232D 10%, #0B0B0C 100%);

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;
    background: rgba(93,2,230,0.50);
    box-shadow: 0 20px 40px 10px rgba(0,0,0,0.50);
    display: inline-block;
    padding: 20px 30px 30px;
    backdrop-filter: blur(20px);
    max-width: 520px;
  }

  .logos {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 320px;
    img {
      margin: 0 10px;
    }
  }
}

.faq-section {
  padding: 100px 0;
  background-color: #1F1E26;
  background-image: url("../../assets/images/faq-background.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  .particles {
    animation-name: none !important;
  }

  .section-heading {
    margin-bottom: 36px;
    h2, p {
      text-align: left;
    }
  }
  .faqs {
    max-width: 720px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    position: relative;
    z-index: 50;

    .col {
      .question {
        margin-bottom: 20px;
      }
    }
  }
}

footer {
  .top {
    padding: 100px 0;
    .mobile {
      display: none;
    }
    .container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    h3 {
      font-size: 22px;
      color: #FFFFFF;
      line-height: 32px;
    }
    ul {
      li {
        margin-top: 10px;
        a {
          opacity: 0.8;
          font-size: 18px;
          line-height: 26px;
          color: #FFFFFF;
        }
      }
    }
  }
  .bottom {
    background-color: #5D02E6;
    padding: 15px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    p, a {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: $fw-medium;
    }
    .socials {
      a {
        margin-left: 10px;
      }
    }
  }
}

@keyframes particles {
  0%, 10% {
    transform: rotate(0);
  }
  11%, 20% {
    transform: rotate(115deg);
  }
  21%, 30% {
    transform: rotate(180deg);
  }
  31%, 40% {
    transform: rotate(0);
  }
  41%, 50% {
    transform: rotate(40deg);
  }
  51%, 60% {
    transform: rotate(180deg) translateY(-10px);
  }
  61%, 70% {
    transform: rotate(-180deg) scaleX(-1);
  }
  71%, 80% {
    transform: rotate(180deg);
  }
  81%, 90% {
    transform: rotate(220deg) translateY(20px);
  }
  91%, 100% {
    transform: rotate(0);
  }
}

@keyframes flicker {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .8;
  }
}

@keyframes logoTilt {
  0%, 100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-15deg);
  }
}

@keyframes text {
  0% {
    opacity: 0;
    transform: scale(.7);
  }
  30%, 70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5) rotate(-45deg);
  }
}

@media (max-width: 750px) {
  nav {
    padding: 10px;
    transition: height 400ms ease;
    height: 80px;
    .container {
      padding: 0;
    }
    .logo {
      z-index: 1001;
    }
    ul {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      backdrop-filter: blur(20px);
      transform: translateY(-100px);
      transition: transform 400ms ease, opacity 400ms ease;
      li {
        margin: 20px;
      }
    }
    .button.mobile {
      display: block;
      height: 54px;
      width: 54px;
      position: relative;
      z-index: 1000;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
    .button.desktop {
      display: none;
    }

    &.shown {
      height: 100vh;
      ul {
        opacity: 1;
        transform: translateY(0);
        pointer-events: initial;
      }
    }
  }
  header.header {
    padding: 150px 0;
    .cta {
      background-position: center;
      height: 400px;
      .button-group {
        width: 100%;
        .button {
          display: block;
          width: 100%;
          margin: 10px 0;
          text-align: center;
        }
      }
    }
  }
  .features-grid {
    grid-template-columns: 1fr !important;
    .feature {
      text-align: center;
      flex-direction: column;
      .icon {
        display: block;
        margin: 0 auto;
        display: block;
      }
    }
  }
  .faqs {
    grid-template-columns: 1fr !important;
    .question {
      header svg {
        top: 14px;
      }
    }
    .col {
      .question:last-child {
        margin-bottom: 0;
      }
    }
  }

  footer {
    .top {
      padding: 50px 0;
      position: relative;
      h3 {
        margin-top: 30px;
      }
      .socials.mobile {
        display: block;
        position: absolute;
        top: 55px;
        right: 20px;
        a {
          margin-left: 10px;
        }
      }
    }
    .bottom {
      padding: 30px 0;
      p {
        margin-bottom: 20px;
      }
    }

    .container {
      flex-direction: column;
    }

    .desktop {
      display: none;
    }
  }
}
